import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'

import Verified from './../Assets/SI-Vetted-Verified.png'


const Footer = () => {


  return(
    <footer className="footer dark-section p-4 m-0 row">
      <ListGroup className="col-12 col-md-6 text-center text-md-left mb-4 mb-md-0">
        <h5>Title Insurance Underwriters</h5>
        <ListGroup.Item as="a" variant="light" href="https://www.fntic.com/" target="_blank">Fidelity National Title</ListGroup.Item>
        <Image src={Verified} className="mt-3 ml-2" style={{width: "100px"}} alt="Secure Insight Seal" fluid />
      </ListGroup>
      <div className="col-12 col-md-6">
        <h5 className="text-center">Contact</h5>
        <div className="text-center">
          <p className="my-1"><a href="mailto:hello@bridges-ss.com">hello@bridges-ss.com</a></p>
          <p className="my-1"><a href="tel:1-412-209-3169">412-209-3169</a></p>
        </div>
      </div>
      <div className="footer__bottom border-top d-flex justify-content-between col-12 mt-3">
        <span>© Copyright {new Date().getFullYear()} Bridges Settlement Services</span>
      </div>
    </footer>
  )
}

export default Footer
