import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Slide } from "react-awesome-reveal";
import Logo from './../../Assets/bridges-logo.png'

const Summary = () => {

  return(
    <Row id="summary" className="section dark-section text-center justify-content-center py-5">
      <Col xs={12} md={4}>
        <Slide triggerOnce><Image src={Logo} className="mb-4" alt="Superior Closings Icon" fluid/></Slide>
      </Col>
      <Col xs={12} md={8} className="d-flex align-items-center">
        <div className="text-md-left">
          <Slide triggerOnce cascade>
            <h2 className="font-weight-bold">Our Mission Statement</h2>
            <p>Our goal is to deliver the highest quality of service to our clients and their customers throughout the Greater Pittsburgh Area.  We guarantee that all parties involved in the transaction will have an exceptional experience.</p>
            <p>At <strong>Bridges Settlement Services</strong> our experienced team works diligently to make sure the closing goes smoothly. Our staff has years of experience working in Western PA, and our streamlined process along with our experience makes it easy to work with us.</p>
          </Slide>
        </div>
      </Col>
    </Row>
  )
}

export default Summary
