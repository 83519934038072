// import { useState } from 'react'
import { Fade } from "react-awesome-reveal";

import Navigation from './Navbar'

import Video from './../Assets/pittsburgh_bg.mp4'
import Logo from './../Assets/bridges-logo--white.png'

const Header = () => {

  const HeaderBackground = () => {
    return (
      <div className="background">
        <video className="background__video" autoPlay loop muted id="bgvid">
          <source src={Video} />
        </video>
      </div>
    )
  }

  return (
    <header className="header">
      <Navigation/>
      <HeaderBackground/>
      <div className="header__content">
        <Fade triggerOnce>
          <img className="d-block m-auto w-75" src={Logo} alt="Bridges Logo"/>
        </Fade>
      </div>
    </header>
  )
}

export default Header
